<div class="notifications">
  <sc-dropdown class="notifications__dropdown" (openEvent)="_onDropdown($event)" (closeEvent)="_onDropdown($event)" [opened]="isOpen" #dropdown>
    <ng-template scDropdownBtnItemTmp let-opened="opened">
      <a class="notifications__dropdown__btn" (click)="dropdown.handleClick($event)">
        <sc-icon class="notifications__dropdown__btn-icon" icon="icon-bell"></sc-icon>
        <div class="notifications__dropdown__btn-number" *ngIf="unreadNotificationsCount > 0">{{
          unreadNotificationsCount }}</div>
      </a>
    </ng-template>
    <ng-template scDropdownListTmp let-opened="opened">
      <div class="notifications__dropdown__menu" [class.notifications__dropdown__menu--open]="opened" (scClickOutside)="dropdown.handleClickOutside($event)" *ngIf="opened" [@notificationAnimation]="opened">
        <div class="notifications__dropdown__menu__container">
          <div class="notifications__dropdown__menu__main notifications__scrollbar">
            <ul class="notifications__dropdown__menu__main__list" *ngIf="notifications.length > 0">
              <ng-container *ngFor="let group of notifications | scGroupBy: 'dateGroup'">
                <li class="notifications__dropdown__menu__main__list__item-group-title">{{ group.key }}</li>
                <li class="notifications__dropdown__menu__main__list__item" *ngFor="let item of group.value; let last = last;" [ngClass]="{'last': last}" [class.notifications__dropdown__menu__main__list__item--readed]="item.isRead" (click)="_onNotificationClick(item)">
                  <div class="notifications__dropdown__menu__main__list__item__header" *ngIf="item.notificationData">
                    <span class="notifications__dropdown__menu__main__list__item__header-date" *ngIf="item.notificationData.ReceptionDate">
                      {{ getDateLabel(item.notificationData.ReceptionDate) }}
                    </span>
                    <span class="notifications__dropdown__menu__main__list__item__header-separator">&nbsp;-&nbsp;</span>
                    <span class="notifications__dropdown__menu__main__list__item__header-desc text-truncate" *ngIf="item.notificationData.Risk">
                      {{ item.notificationData.Risk.PlateNumber }},
                      {{ item.notificationData.Risk.Brand }}
                      {{ item.notificationData.Risk.Model }}
                      {{ item.notificationData.Risk.Version }}
                    </span>
                  </div>
                  <div class="notifications__dropdown__menu__main__list__item-title" *ngIf="item.notificationType">{{
                    item.notificationType }}</div>
                  <div class="notifications__dropdown__menu__main__list__item-desc" *ngIf="item.notificationData && item.notificationData.Description">{{
                    item.notificationData.Description }}</div>
                  <div class="notifications__dropdown__menu__main__list__item-actions" *ngIf="item.actions && item.actions.length > 0">
                    <ul class="notifications__dropdown__menu__main__list__item-actions__list">
                      <li class="notifications__dropdown__menu__main__list__item-actions__list__item" *ngFor="let action of item.actions | scFilter: (actionClick !== null || actionClick !== '') && (label !== null || label !== '')">
                        <a class="notifications__dropdown__menu__main__list__item-actions__list__item-link" (click)="_onNotificationActionClick(item, action)">
                          {{ action.label }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ng-container>
              <li class="notifications__dropdown__menu__main__list__item__more-items" *ngIf="notifications.length > 0 && hasMoreItems">
                <sc-button size="small" type="link" (onClick)="getNotifications()" [loading]="loadingNotifications">Cargar más</sc-button>
              </li>
            </ul>
            <div class="notifications__dropdown__menu__main__empty-state" *ngIf="notifications.length === 0">
              <div class="notifications__dropdown__menu__main__empty-state-msg" *ngIf="!loadingNotifications">Sin
                notificaciones</div>
              <sc-icon class="notifications__dropdown__menu__main__empty-state-loading" icon="fa-duotone fa-spinner-third" *ngIf="loadingNotifications"></sc-icon>
            </div>
          </div>
          <!-- Footer -->
          <div class="notifications__dropdown__menu__footer"></div>
        </div>
      </div>
    </ng-template>
  </sc-dropdown>
</div>
