import { Component, ViewChild } from '@angular/core';
import { ScModalDialogConfig } from '@sc/claim-components';
import { FileDownloadService } from 'app/core/services/file-download.service';
import { BaremoType } from 'app/shared/models/baremoType.enum';

@Component({
    selector: 'sc-download-baremo-modal',
    templateUrl: './sc-download-baremo-modal.component.html',
    styleUrls: ['./sc-download-baremo-modal.component.scss']
})
export class ScDownloadBaremoModalComponent {

    @ViewChild('modal', { static: true }) modal;
    public scDownloadBaremoModalConfig: ScModalDialogConfig;

    public downloadAll = BaremoType.all;
    public downloadCar = BaremoType.car;
    public downloadPickUp = BaremoType.pickup;

    constructor(private fileDownloadService: FileDownloadService) {
        this.scDownloadBaremoModalConfig = {
            size: 'large',
            disableClose: false,
            title: 'Baremos de daños',
            titleAlign: 'center',
            titleColor: 'primary-text-dark',
            disableScroll: true,
            showDefaultFooter: false,
            showDefaultHeader: true
        };
    }

    public downloadFile(key: BaremoType): void {
        this.fileDownloadService.downloadBeremoFilesWithAuth(key);
        this.modal.close();
    }
}
